<template>
  <div class="mx-auto">
    <spinner large center />
  </div>
</template>

<script>
export default {
  metaInfo: {
    title: "Email verification",
  },

  data: () => ({
    formResponse: {},
  }),

  mounted() {
    this.$store
      .dispatch("auth/verifyEmail", this.$route.params.token)
      .then((data) => {
        this.$router
          .replace({
            name: "auth.register.onboard",
            params: {
              token: data.data.registration_token,
            },
          })
          .then(() => {
            this.$toast.success(data.message);
          });
      })
      .catch(() => {
        this.$router.replace({ name: "auth.login" }).then(() => {
          this.$toast.error(this.$t("common.error"));
        });
      });
  },
};
</script>
